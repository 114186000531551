import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "The never ending journey of a developer",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:49:03.000Z",
  "url": "/2018/02/the-never-ending-journey-of-a-developer/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I am a developer for quite a while now (7 years to be exact). I remember the old days when I only knew some html & css and create nice little websites, but those days are long gone and the complexity of products and technologies gone much higher.`}</p>
    <p>{`For example, if I want to build a simple site with some dynamic data and interaction this is the tech stack I`}{`’`}{`ll need to know:`}</p>
    <p>{`Frontend `}{`–`}</p>
    <p>{`html, css, sass or less, javascript, js frameworks like: Vue.js, Angular, React, a build systems like gulp, grunt, webpack, testing like karma, mocha and much more! 😀`}</p>
    <p>{`Backend `}{`–`}</p>
    <p>{`let`}{`’`}{`s focus on one language like php, composer, laravel or symfony or other, caching, memcache, redis, design patterns, singleton, repositories, observers etc, databases, mysql, postgresql, mongo. Testing: phpunit, codeception, phpspec etc.`}</p>
    <p>{`Devops `}{`–`}</p>
    <p>{`AWS, Digital Ocean as cloud providers, server automations with ansible, chef or others, Docker, jenkins, fabric and more.`}</p>
    <p>{`All of the above are just a drop in the ocean of the things we need to know, and will need to learn in the future.`}</p>
    <p>{`I am not complaining, I`}{`’`}{`m just proud of the progress I had from 7 years ago till now. I love learning and this is what makes me tick in our awesome job 🙂`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      